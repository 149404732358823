import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Card, Col, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'react-chartjs-2';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import StyleVariables from '../../styles/_variables.scss';
import './GlobalActivity.scss';



const chartDatasetConf = {
  fill: false,
  lineTension: 0.1,
  backgroundColor: 'transparent',
  borderColor: 'rgba(75,192,192,1)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(75,192,192,1)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

const chartDataMap = {
  total: {
    ...chartDatasetConf,
    label: 'Total',
    borderColor: StyleVariables.colorPrimaryLight,
    pointBorderColor: StyleVariables.colorPrimary,
  },
  dc: {
    ...chartDatasetConf,
    label: 'Décès',
    borderColor: StyleVariables.colorDangerLight,
    pointBorderColor: StyleVariables.colorDanger,
  },
  rea: {
    ...chartDatasetConf,
    label: 'En réanimation',
    borderColor: StyleVariables.colorWarningLight,
    pointBorderColor: StyleVariables.colorWarning,
  },
  hosp: {
    ...chartDatasetConf,
    label: 'Hospitalisations',
    borderColor: StyleVariables.colorInfoLight,
    pointBorderColor: StyleVariables.colorInfo,
  },
  rad: {
    ...chartDatasetConf,
    label: 'Guérisons',
    borderColor: StyleVariables.colorSuccessLight,
    pointBorderColor: StyleVariables.colorSuccess,
  }
}

const GlobalActivity = ({ data, globalStats, cumulChartItem, dailyChartItems }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isready, setIsReady] = React.useState(false);

  let dailyChart = null;

  React.useEffect(() => {
    setIsMobile(window.matchMedia('only screen and (max-width: 760px)').matches);
    setIsReady(true);
  }, []);

  const chartData = {
    data: {
      labels: data.allDates,
      responsive: true,
      maintainAspectRatio: false,
      datasets: cumulChartItem.map((key) => ({
        ...chartDataMap[key],
        data: Object.values(data.dataByDate).map((item) => item[key]),
      })),
    },
    options: {
      title: {
        display: true,
        text: 'Evolution cumulée',
      },
    },
  };

  if (data.daily) {
    dailyChart = {
      data: {
        labels: Object.keys(data.daily),
        responsive: true,
        maintainAspectRatio: false,
        datasets: dailyChartItems.map((key) => ({
          ...chartDataMap[key],
          data: Object.values(data.daily).map((item) => item[key]),
        })),
      },
      options: {
        title: {
          display: true,
          text: 'Evolution au jour le jour',
        },
      },
    };
  }

  if (!isready) {
    return null;
  }

  let capacityPercentage = null;

  if (data.reaCapacity) {
    capacityPercentage = Math.round((globalStats.critical / data.reaCapacity) * 100);
  }

  return (
    <Card className="global-activity">
      <Nav defaultActiveKey="confirmed">
          <Nav.Item>
            <Nav.Link eventKey="total">
              <div className="icon-wrapper primary">
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </div>
              <h4>{globalStats.cases.toLocaleString()}</h4>
              {globalStats.todayCases !== null && (
                <div><Badge variant="danger">+{globalStats.todayCases.toLocaleString()} depuis hier</Badge></div>
              )}
              <span className="type-name">Cas confirmés <span className="text-small">(cumulés)</span></span>
              <span className="text-small">{globalStats.casesPerOneMillion} Cas/million d'habitant</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="dc">
              <div className="icon-wrapper danger">
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </div>
              <h4>{globalStats.deaths.toLocaleString()}</h4>
              {globalStats.todayDeaths !== null && (
                <div><Badge variant="danger">+{globalStats.todayDeaths.toLocaleString()} depuis hier</Badge></div>
              )}
              <span className="type-name">Décès <span className="text-small">(cumulés)</span></span>
              <span className="text-small">{globalStats.deathsPerOneMillion} Décès/million d'habitant</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="rea">
              <div className="icon-wrapper warning">
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </div>
              <h4>{(globalStats.active - globalStats.deaths - globalStats.recovered).toLocaleString()}</h4>
              <div>
                {globalStats.critical ? (
                  <>
                    <Badge variant="danger">dont {globalStats.critical.toLocaleString()} en réanimations</Badge>
                    {data.reaCapacity && (
                      <Badge variant={capacityPercentage > 100 ? 'danger' : capacityPercentage > 70 ? 'warning' : 'success'}>{capacityPercentage}% de la capacité</Badge>
                    )}
                  </>
                ) : (
                  <Badge variant="light">Réanimations inconnues</Badge>
                )}
              </div>
              <span className="type-name">Hospitalisations <span className="text-small">(en cours)</span></span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="rad">
              <div className="icon-wrapper success">
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </div>
              <h4>{globalStats.recovered.toLocaleString()}</h4>
              <span className="type-name">Soignés</span>
              <span className="text-small">(Cumulés)</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="active">
              <div className="icon-wrapper info">
                <FontAwesomeIcon icon={faUsers} className="icon" />
              </div>
              <h4>{globalStats.active.toLocaleString()}</h4>
              <span className="type-name">Cas actifs</span>
              <span className="text-small">(En cours)</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Card.Body>
          <Card.Title>Evolution de l'activité du virus</Card.Title>

          <Row>
            <Col xs={12} md={dailyChart ? 6 : 12}>
              <div className="chart-wrapper" >
                <Line {...chartData} height={isMobile ? 300 : 156} />
              </div>
            </Col>

            {dailyChart && (
              <Col xs={12} md={6}>
                <div className="chart-wrapper" >
                  <Line {...dailyChart} height={isMobile ? 300 : 156} />
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
    </Card>
  );
};

GlobalActivity.propTypes = {
  data: PropTypes.object.isRequired,
  dailyChartItems: PropTypes.array.isRequired,
};

export default GlobalActivity;
