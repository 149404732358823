import PropTypes from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'gatsby';

import './CardTable.scss';

const CardTable = ({ data, dataKeys, headers, title, pathBase }) => (
  <Card className="card-table">
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Table hover className="table table-striped sticky-header">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, index) => (
            <tr key={index}>
              {dataKeys.map((dataKey, keyIndex) => (
                <td key={keyIndex} className={!keyIndex ? 'text-primary' : ''}>
                  {pathBase ? (
                    <Link to={`${pathBase}/${dataItem.dep || dataItem.code || dataItem.countryInfo.iso2.toLowerCase()}`}>{dataItem[dataKey]}</Link>
                  ): (
                    dataItem[dataKey]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

CardTable.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeys: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  pathBase: PropTypes.string,
  title: PropTypes.string,
};

CardTable.defaultProps = {
  pathBase: null,
  title: '',
};

export default CardTable;
