import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';

import './PageTitle.scss';

const PageTitle = ({ title }) => (
  <Row className="page-title">
    <Col sm={12}>
    <Breadcrumb>
      <Breadcrumb.Item href="#">{title}</Breadcrumb.Item>
    </Breadcrumb>
    </Col>
  </Row>
);

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;
